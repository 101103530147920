import Vue from "vue";
import Vuex from "vuex";
import Cookie from "js-cookie";
import Router from "@/router/index";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    authPermissions: []
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setAuthPermissions(state, authPermissions) {
      state.authPermissions = authPermissions;
    }
  },
  actions: {
    logout() {
      const DOMAIN = process.env.VUE_APP_DOMAIN;
      Cookie.remove("xinli_token", { domain: DOMAIN });
      Router.push({ name: "Login" });
    }
  },
  modules: {}
});
