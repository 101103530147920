import UserDataList from "@/views/UserDataList.vue";
import UserDataDetail from "@/views/UserDataDetail.vue";

export default [
  {
    path: "user",
    name: "User",
    component: { render: h => h("router-view") },
    redirect: { name: "UserDataList" },
    meta: {
      nav: {
        svg: {
          name: "userdata",
          class: "nav-svg"
        },
        title: "用户测评数据"
      },
      breadcrumb: {
        title: "用户测评数据"
      }
    },
    children: [
      {
        path: "list",
        name: "UserDataListRoot",
        component: { render: h => h("router-view") },
        redirect: { name: "UserDataList" },
        children: [
          {
            path: "",
            name: "UserDataList",
            component: UserDataList,
            meta: {
              title: "心力测评 - 测评数据列表",
              nav: {
                title: "测评数据列表"
              }
            }
          },
          {
            path: "detail/:id",
            name: "UserDataDetail",
            component: UserDataDetail,
            meta: {
              title: "心力测评 - 测评数据详情",
              breadcrumb: {
                title: "测评数据详情"
              }
            }
          }
        ]
      }
    ]
  }
];
