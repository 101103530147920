import API from "@/global/request/api.js";
import axios from "@/global/request/axios.js";

const evaluationService = {
  evaluationList(params) {
    return axios.get(API.evaluation, params);
  },
  evaluationDetail(id) {
    return axios.get(API.evaluationId(id));
  }
};

export default evaluationService;
