const data = [
  {
    account: "xinliadmin",
    pwd: "xinli666"
  },
  {
    account: "18618288603",
    pwd: "jiker666"
  }
];

const validAccount = function({ account, pwd }) {
  let result = false;
  data.forEach(_s => {
    if (_s.account === account && _s.pwd === pwd) {
      result = true;
    }
  });
  return result;
};

export default validAccount;
