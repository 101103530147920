import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./route.js";
import Nprogress from "nprogress";
import "nprogress/nprogress.css";
import Cookie from "js-cookie";
import { Message } from "element-ui"; //通知
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return true;
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

router.beforeEach((to, from, next) => {
  Nprogress.start();
  if (to.meta.title) document.title = to.meta.title;
  const Token = Cookie.get("xinli_token");
  if (!Token && to.name !== "Login") {
    Message.error("请先登录");
    next({ name: "Login" });
    return;
  }
  next();
});
router.afterEach(() => {
  Nprogress.done();
});

export default router;
