<template>
  <div class="container">
    <div class="login-title">
      <img src="@/assets/images/logo.png" alt="" class="login-title-logo" />
      <p class="login-title-text">心力测评</p>
    </div>
    <el-form
      class="login-form"
      :model="loginForm"
      ref="loginForm"
      :rules="loginRules"
    >
      <el-form-item prop="account">
        <el-input
          v-model="loginForm.account"
          placeholder="账号"
          class="form-input"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          placeholder="密码"
          class="form-input"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="submit-button"
          type="primary"
          plain
          @click="handleLogin"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import validAccount from "@/account/account";
export default {
  data() {
    return {
      loginForm: {
        account: "",
        password: ""
      },
      loginRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          let params = {
            account: this.loginForm.account,
            pwd: this.loginForm.password
          };
          if (!validAccount(params)) {
            this.$message.error("账号密码错误");
            return;
          }
          Cookie.set("xinli_token", "xinli&jike", {
            expires: 7,
            domain: process.env.VUE_APP_DOMAIN
          });
          this.$message.success("登录成功");
          this.$router.push({
            name: "User"
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-title {
  display: flex;
  margin-bottom: 50px;
}
.login-title-logo {
  height: 60px;
  width: auto;
  margin-right: 25px;
}
.login-title-text {
  font-size: 36px;
  line-height: 60px;
}
.form-input {
  width: 340px;
}
.submit-button {
  width: 340px;
}
</style>
