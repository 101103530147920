<template>
  <div class="container" v-loading="loading">
    <bread-crumb></bread-crumb>
    <div class="userInfo">
      <el-row type="flex" class="userInfo-item">
        <el-col :span="5"
          ><p class="label">姓名：</p>
          <p class="content">{{ evaluationUserInfo.nickName }}</p></el-col
        >
        <el-col :span="5"
          ><p class="label">手机号：</p>
          <p class="content">{{ phoneNumber }}</p></el-col
        >
      </el-row>
      <el-row type="flex" class="userInfo-item">
        <el-col :span="5"
          ><p class="label">性别：</p>
          <p class="content">
            {{ evaluationUserInfo.gender === 0 ? "男" : "女" }}
          </p></el-col
        >
        <el-col :span="5"
          ><p class="label">年龄：</p>
          <p class="content">{{ evaluationUserInfo.age }}</p></el-col
        >
      </el-row>
      <el-row type="flex" class="userInfo-item">
        <el-col :span="5"
          ><p class="label">行业：</p>
          <p class="content">{{ evaluationUserInfo.industry }}</p></el-col
        >
        <el-col :span="5"
          ><p class="label">职位：</p>
          <p class="content">{{ evaluationUserInfo.position }}</p></el-col
        >
      </el-row>
      <el-row type="flex" class="userInfo-item">
        <el-col :span="5"
          ><p class="label">教育背景：</p>
          <p class="content">
            {{ evaluationUserInfo.educationBackground }}
          </p></el-col
        >
      </el-row>
    </div>
    <div class="userResult">
      <div class="userResult-data">
        <div class="data-point">
          <p class="data-point-item">
            <span class="item-label">总分：</span
            ><span class="item-content">{{ evaluationResult.totalScore }}</span>
          </p>
          <p
            class="data-point-item"
            v-for="item in evaluationResult.types"
            :key="item.index"
          >
            <span class="item-label">{{ item.name }}：</span
            ><span class="item-content">{{ item.score }}</span>
          </p>
        </div>
        <div class="data-quality">
          <p
            class="data-quality-item"
            v-for="item in children"
            :key="item.index"
          >
            <span class="item-label">{{ item.name }}：</span
            ><span class="item-content">{{ item.score }}</span>
          </p>
        </div>
      </div>
      <div class="userResult-chart">
        <div class="mychart-parent" id="myChart"></div>
      </div>
    </div>
  </div>
</template>
<script>
import evaluationService from "@/global/service/evaluation";
import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";
export default {
  data() {
    return {
      evaluationResult: {},
      evaluationUserInfo: {},
      phoneNumber: null,
      children: [],
      loading: false
    };
  },
  mounted() {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      this.loading = true;
      evaluationService
        .evaluationDetail(id)
        .then(res => {
          this.evaluationResult = res.evaluationResult;
          this.phoneNumber = res.wechatUser.phoneNumber;
          this.evaluationUserInfo = res.wechatUser.userInfo;
          Object.assign(this.evaluationUserInfo, res.wechatUser.wechatInfo);
          let result = [];
          res.evaluationResult.types.map(data => {
            data.children.map(item => {
              result.push(item);
            });
          });
          this.children = result;
          this.showChart();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showChart() {
      const { DataView } = DataSet;
      let data = this.children.map(data => {
        return (data = {
          item: data.name,
          标准: 20,
          能力得分: data.score
        });
      });
      const dv = new DataView().source(data);
      dv.transform({
        type: "fold",
        fields: ["标准", "能力得分"], // 展开字段集
        key: "user", // key字段
        value: "score" // value字段
      });
      const chart = new Chart({
        container: "myChart",
        autoFit: true,
        height: 400
      });
      chart.data(dv.rows);
      chart.scale("score", {
        min: 0,
        max: 30
      });
      chart.coordinate("polar", {
        radius: 0.8
      });
      chart.axis("item", {
        line: null,
        tickLine: null,
        grid: {
          line: {
            style: {
              lineDash: null
            }
          }
        }
      });
      chart.axis("score", {
        line: null,
        tickLine: null,
        grid: {
          line: {
            type: "circle",
            style: {
              lineDash: null
            }
          },
          alternateColor: "rgba(0, 0, 0, 0.04)"
        }
      });

      chart
        .point()
        .position("item*score")
        .color("user")
        .shape("circle")
        .size(4)
        .style({
          stroke: "#fff",
          lineWidth: 1,
          fillOpacity: 1
        });
      chart
        .line()
        .position("item*score")
        .color("user")
        .size(2);
      chart.render();
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
}
.userInfo-item {
  margin-bottom: 15px;
}
.userInfo {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  /deep/.el-col {
    display: flex;
    align-items: center;
  }
  .label {
    font-size: 14px;
    color: #333;
  }
  .content {
    font-size: 14px;
    color: #333;
  }
}
.userResult {
  background: #fff;
  padding: 20px 50px 20px 20px;
  display: flex;
  align-items: flex-end;
}
.userResult-data {
  flex: 1;
}
.userResult-chart {
  flex: 1;
}
.mychart-parent {
}
.data-point {
  display: flex;
  margin-bottom: 15px;
  .data-point-item {
    margin-right: 28px;
  }
}
.data-quality-item {
  margin-bottom: 15px;
  .item-label {
    margin-right: 10px;
  }
}
.item-label {
  font-size: 14px;
  color: #333;
}
.item-content {
  font-size: 14px;
  color: #333;
}
</style>
