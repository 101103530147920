<template>
  <div class="container">
    <bread-crumb></bread-crumb>
    <el-table :data="dataTable" border class="data-table" v-loading="loading">
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="手机号" prop="phoneNumber"></el-table-column>
      <el-table-column label="总得分" prop="totalScore"></el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <div>
            <p class="option-item" @click="handleToDetail(scope.row.id)">
              查看详情
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, prev, pager, next, jumper"
      @current-change="handleChangePage"
      :current-page.sync="pagination.currentPage"
      :page-size="pagination.pageSize"
      :total="pagination.total"
      :hide-on-single-page="true"
    >
    </el-pagination>
  </div>
</template>

<script>
import evaluationService from "@/global/service/evaluation";
export default {
  data() {
    return {
      loading: false,
      dataTable: [],
      pagination: {
        currentPage: 1,
        total: 0,
        pageSize: 20
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleToDetail(id) {
      this.$router.push({
        name: "UserDataDetail",
        params: {
          id
        }
      });
    },
    handleChangePage(current) {
      this.pagination.currentPage = current;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        page: this.pagination.currentPage - 1,
        size: this.pagination.pageSize
      };
      evaluationService
        .evaluationList(params)
        .then(res => {
          this.dataTable = res.content;
          this.pagination.currentPage = res.number + 1;
          this.pagination.total = res.totalElements;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 20px;
}
.option-item {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
</style>
