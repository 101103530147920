import routeUser from "./routeUser.js";
import TheLayout from "@/components/TheLayout.vue";
import Login from "@/views/Login.vue";
export default [
  {
    path: "/",
    component: TheLayout,
    redirect: { name: "User" },
    children: [...routeUser]
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
    meta: {
      title: "心力测评 - 登录"
    }
  }
];
